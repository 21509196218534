import { KEY_AUTH_TOKEN } from "_constants";
import { transactionsFilter } from "_utils";
import { useState } from "react";
import tenantConfig from "tenantConfig";

const API_URL = `${tenantConfig[process.env.REACT_APP_ENV].apiBaseUrl}`;

export const DownloadUserSalesReportButton = (props) => {
  const [loading, setLoading] = useState(false);
  const downloadExcel = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem(KEY_AUTH_TOKEN);
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: token ? `Bearer ${token}` : "",
        },
        body: JSON.stringify(transactionsFilter(null, null, props.filters)),
      };
      const response = await fetch(
        `${API_URL}/api/v1/transactions/download-user-sales-report`,
        options
      );
      setLoading(false);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();

      // Create a Blob URL for the file
      const url = window.URL.createObjectURL(blob);

      // Create a download link and trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = "user-sales-report.xlsx";
      a.click();

      // Release the Blob URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading Excel:", error);
    }
  };
  return (
    <button
      type="button"
      className="btn btn-secondary"
      disabled={loading}
      onClick={downloadExcel}
    >
      Export xls
    </button>
  );
};
