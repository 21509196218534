import { Fragment } from "react";
import tenantConfig from "tenantConfig";

export const OfferInfoInTable = (props) => {
  return (
    <Fragment>
      <span>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0 me-3">
            <div className="avatar-sm bg-light rounded p-1">
              <img
                src={`${tenantConfig.assetsUrl}/advertiser-logos/${props.offer.logo}`}
                alt={props.offer.offerName}
                className="img-fluid d-block"
              />
            </div>
          </div>
          <div className="flex-grow-1">
            <h5 className="fs-14 mb-1">
              <a
                href={props.offer.website}
                target="_blank"
                rel="noreferrer"
                className="text-dark"
              >
                {props.offer.offerName}
              </a>
            </h5>
            <p className="text-muted mb-0">{props.offer.referenceId}</p>
          </div>
        </div>
      </span>
    </Fragment>
  );
};
