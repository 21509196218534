import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { toast } from "react-toastify";
import { KEY_AUTH_TOKEN } from "_constants";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import offersTemplate from "assets/templates/offers_template.xlsx";
import transactionsTemplate from "assets/templates/transactions_template.xlsx";
import { useState } from "react";
import tenantConfig from "tenantConfig";

const API_URL = `${tenantConfig[process.env.REACT_APP_ENV].apiBaseUrl}`;

const schemaUploadOffers = yup.object().shape({
  uploadOffers: yup
    .mixed()
    .test("required", "Please select a file", (value) => {
      return value && value.length;
    }),
});
const schemaUploadTransactions = yup.object().shape({
  uploadTransactions: yup
    .mixed()
    .test("required", "Please select a file", (value) => {
      return value && value.length;
    }),
});
const schemaUploadLogos = yup.object().shape({
  uploadLogos: yup.mixed().test("required", "Please select a file", (value) => {
    return value && value.length;
  }),
});
const schemaUploadBanners = yup.object().shape({
  uploadBanners: yup
    .mixed()
    .test("required", "Please select a file", (value) => {
      return value && value.length;
    }),
});
export const ManualUploads = () => {
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem(KEY_AUTH_TOKEN);
  const {
    register: registerUploadOffers,
    handleSubmit: handleSubmitUploadOffers,
    formState: { errors: errorsUploadOffers },
  } = useForm({
    resolver: yupResolver(schemaUploadOffers),
  });
  const {
    register: registerUploadTransactions,
    handleSubmit: handleSubmitUploadTransactions,
    formState: { errors: errorsUploadTransactions },
  } = useForm({
    resolver: yupResolver(schemaUploadTransactions),
  });
  const {
    register: registerUploadLogos,
    handleSubmit: handleSubmitUploadLogos,
    reset,
    formState: { errors: errorsUploadLogos },
  } = useForm({
    resolver: yupResolver(schemaUploadLogos),
  });
  const {
    register: registerUploadBanners,
    handleSubmit: handleSubmitUploadBanners,
    //reset: resetForm,
    formState: { errors: errorsUploadBanners },
  } = useForm({
    resolver: yupResolver(schemaUploadBanners),
  });

  const onSubmitUploadOffers = async (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("uploadOffers", data.uploadOffers[0]);
    const options = {
      method: "POST",
      body: formData,
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };
    fetch(`${API_URL}/api/v1/advertisers/upload-offers`, options)
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        toast.success(`${result} advertisers have been uploaded successfully.`);
        reset({ uploadOffers: "" });
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  };

  const onSubmitUploadTransactions = async (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("uploadTransactions", data.uploadTransactions[0]);
    const options = {
      method: "POST",
      body: formData,
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };
    fetch(`${API_URL}/api/v1/transactions/upload`, options)
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        toast.success("Transactions have been uploaded successfully.");
        reset({ uploadTransactions: "" });
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  };

  const onSubmitUploadLogos = async (data) => {
    setLoading(true);
    const formData = new FormData();
    for (const key of Object.keys(data.uploadLogos)) {
      formData.append("uploadLogos[]", data.uploadLogos[key]);
    }
    const options = {
      method: "POST",
      body: formData,
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };
    fetch(`${API_URL}/api/v1/advertisers/upload-logos`, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response);
      })
      .then((result) => {
        setLoading(false);
        toast.success("Logos have been uploaded successfully.");
        reset({ uploadLogos: "" });
      })
      .catch((error) => {
        setLoading(false);
        error.json().then((json) => {
          toast.error(json.message);
        });
      });
  };

  const onSubmitUploadBanners = async (data) => {
    setLoading(true);
    const formData = new FormData();
    for (const key of Object.keys(data.uploadBanners)) {
      formData.append("uploadBanners[]", data.uploadBanners[key]);
    }
    const options = {
      method: "POST",
      body: formData,
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };
    fetch(`${API_URL}/api/v1/advertisers/upload-banners`, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response);
      })
      .then((result) => {
        setLoading(false);
        toast.success("Banners have been uploaded successfully.");
        reset({ uploadBanners: "" });
      })
      .catch((error) => {
        setLoading(false);
        error.json().then((json) => {
          toast.error(json.message);
        });
      });
  };

  return (
    <Row>
      <Col lg={4}>
        <Card>
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">
              Upload Advertisers (
              <a href={offersTemplate} target="_blank" rel="noreferrer">
                Download Template
              </a>
              )
            </h4>
          </CardHeader>
          <CardBody className="card-body">
            <form
              onSubmit={handleSubmitUploadOffers(onSubmitUploadOffers)}
              className="pt-3"
            >
              <div className="form-group">
                <input
                  type="file"
                  {...registerUploadOffers("uploadOffers")}
                  className={`form-control ${
                    errorsUploadOffers.uploadOffers ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errorsUploadOffers.uploadOffers?.message}
                </div>
              </div>
              <div className="mt-3">
                <button className="btn btn-primary" disabled={loading}>
                  UPLOAD
                </button>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
      <Col lg={4}>
        <Card>
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">
              Upload Transactions (
              <a href={transactionsTemplate} target="_blank" rel="noreferrer">
                Download Template
              </a>
              )
            </h4>
          </CardHeader>
          <CardBody className="card-body">
            <form
              onSubmit={handleSubmitUploadTransactions(
                onSubmitUploadTransactions
              )}
              className="pt-3"
            >
              <div className="form-group">
                <input
                  type="file"
                  {...registerUploadTransactions("uploadTransactions")}
                  className={`form-control ${
                    errorsUploadTransactions.uploadTransactions
                      ? "is-invalid"
                      : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errorsUploadTransactions.uploadTransactions?.message}
                </div>
              </div>
              <div className="mt-3">
                <button className="btn btn-primary" disabled={loading}>
                  UPLOAD
                </button>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
      <Col lg={4}>
        <Card>
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Upload Logos</h4>
          </CardHeader>
          <CardBody className="card-body">
            <form
              onSubmit={handleSubmitUploadLogos(onSubmitUploadLogos)}
              className="pt-3"
            >
              <div className="form-group">
                <input
                  type="file"
                  multiple
                  {...registerUploadLogos("uploadLogos")}
                  className={`form-control ${
                    errorsUploadLogos.uploadLogos ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errorsUploadLogos.uploadLogos?.message}
                </div>
              </div>
              <div className="mt-3">
                <button className="btn btn-primary" disabled={loading}>
                  UPLOAD
                </button>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
      <Col lg={4}>
        <Card>
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Upload Banners</h4>
          </CardHeader>
          <CardBody className="card-body">
            <form
              onSubmit={handleSubmitUploadBanners(onSubmitUploadBanners)}
              className="pt-3"
            >
              <div className="form-group">
                <input
                  type="file"
                  multiple
                  {...registerUploadBanners("uploadBanners")}
                  className={`form-control ${
                    errorsUploadBanners.uploadBanners ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errorsUploadBanners.uploadBanners?.message}
                </div>
              </div>
              <div className="mt-3">
                <button className="btn btn-primary" disabled={loading}>
                  UPLOAD
                </button>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
