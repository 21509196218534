/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
} from "reactstrap";
import { useLogout, useUserInfo } from "_hooks/Shared";
import { fullName } from "_utils";
import tenantConfig from "tenantConfig";

export const Header = () => {
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const {
    userInfo: { firstName, lastName, referenceId },
  } = useUserInfo();

  const logout = useLogout();
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth;

    if (windowSize > 767)
      document.querySelector(".hamburger-icon").classList.toggle("open");

    //For collapse horizontal menu
    if (document.documentElement.getAttribute("data-layout") === "horizontal") {
      document.body.classList.contains("menu")
        ? document.body.classList.remove("menu")
        : document.body.classList.add("menu");
    }

    //For collapse vertical menu
    if (document.documentElement.getAttribute("data-layout") === "vertical") {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "sm"
          ? document.documentElement.setAttribute("data-sidebar-size", "")
          : document.documentElement.setAttribute("data-sidebar-size", "sm");
      } else if (windowSize > 1025) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "lg"
          ? document.documentElement.setAttribute("data-sidebar-size", "sm")
          : document.documentElement.setAttribute("data-sidebar-size", "lg");
      } else if (windowSize <= 767) {
        document.body.classList.add("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
    }

    //Two column menu
    if (document.documentElement.getAttribute("data-layout") === "twocolumn") {
      document.body.classList.contains("twocolumn-panel")
        ? document.body.classList.remove("twocolumn-panel")
        : document.body.classList.add("twocolumn-panel");
    }
  };

  return (
    <Fragment>
      <header id="page-topbar">
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box horizontal-logo">
                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img
                      src={`${tenantConfig.assetsUrl}/app-logos/${tenantConfig.logo}`}
                      alt=""
                      height="40"
                    />
                  </span>
                  <span className="logo-lg">
                    <img
                      src={`${tenantConfig.assetsUrl}/app-logos/${tenantConfig.logo}`}
                      alt=""
                      height="70"
                    />
                  </span>
                </Link>
              </div>

              <button
                onClick={toogleMenuBtn}
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                id="topnav-hamburger-icon"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
            </div>

            <div className="d-flex align-items-center">
              <div className="ms-1 header-item d-none d-sm-flex">
                <button
                  onClick={() =>
                    (window.location = "skype:relationshipmanager31?chat")
                  }
                  type="button"
                  className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode"
                  id="tooltipContactManager"
                >
                  <i className="bx bxl-skype fs-22"></i>
                </button>
                <UncontrolledTooltip
                  placement="bottom"
                  target="tooltipContactManager"
                >
                  Contact our manager now
                </UncontrolledTooltip>
              </div>

              <Dropdown
                isOpen={isProfileDropdown}
                toggle={toggleProfileDropdown}
                className="ms-sm-3 header-item topbar-user"
              >
                <DropdownToggle tag="button" type="button" className="btn">
                  <span className="d-flex align-items-center">
                    <span className="text-start ms-xl-2">
                      <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                        {fullName(firstName, lastName)}
                      </span>
                      <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                        {referenceId}
                      </span>
                    </span>
                  </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <h6 className="dropdown-header">Welcome {firstName}!</h6>
                  <DropdownItem>
                    <Link to={`profile`}>
                      <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                      <span className="align-middle">Profile</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem onClick={logout}>
                    <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                    <span className="align-middle" data-key="t-logout">
                      Logout
                    </span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  );
};
