import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuthToken, useUserInfo } from "_hooks/Shared";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_PROFILE, LOGIN_MUTATION } from "_queries/Shared";
import {
  APP_NAME,
  HOME_PAGE_ADMIN,
  HOME_PAGE_AFFILIATE,
  HOME_PAGE_EDITOR,
  userRole,
} from "_constants";
import { transformGQLErrors } from "_utils";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,
  Row,
} from "reactstrap";
import { ParticlesAuth } from "_components";
import { Fragment } from "react";
import tenantConfig from "tenantConfig";

export const Login = () => {
  const navigate = useNavigate();
  const { setAuthToken, removeAuthToken } = useAuthToken();
  const { setUserInfo } = useUserInfo();
  const [getUser, { loading: loadingProfile }] = useLazyQuery(GET_PROFILE);
  const [login, { loading }] = useMutation(LOGIN_MUTATION, {
    errorPolicy: "all",
  });
  const [searchParams] = useSearchParams();
  if (searchParams.get("msg")) {
    toast.error(searchParams.get("msg"));
  }

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const disableForm = loading || loadingProfile;
  const onSubmit = async ({ email, password }) => {
    removeAuthToken();
    const response = await login({
      variables: { email, password },
    });

    if (response) {
      const accessToken =
        response &&
        response.data &&
        response.data.logIn &&
        response.data.logIn.access_token;

      if (accessToken) {
        setAuthToken(accessToken);
        const userResp = await getUser();

        if (userResp) {
          const userInfo = userResp && userResp.data && userResp.data.me;

          if (userInfo) {
            setUserInfo(userInfo);
            if (userInfo.roles.includes(userRole.ADMIN)) {
              navigate(HOME_PAGE_ADMIN, { replace: true });
            } else if (userInfo.roles.includes(userRole.EDITOR)) {
              navigate(HOME_PAGE_EDITOR, { replace: true });
            } else if (userInfo.roles.includes(userRole.AFFILIATE)) {
              navigate(HOME_PAGE_AFFILIATE, { replace: true });
            }
          }
        }
      }
    }

    //Show errors if any
    transformGQLErrors(response).map((e) => toast.error(e));
  };

  return (
    <Fragment>
      <div className="auth-page-wrapper pt-5">
        <ParticlesAuth>
          <div className="auth-page-content">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="text-center mt-sm-5 mb-4 text-white-50">
                    <div>
                      <Link to="/" className="d-inline-block auth-logo">
                        <img
                          src={`${tenantConfig.assetsUrl}/app-logos/${tenantConfig.logo}`}
                          alt=""
                          height="100"
                        />
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="mt-4">
                    <CardBody className="p-4">
                      <div className="text-center mt-2">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p className="text-muted">
                          Sign in to continue to {APP_NAME}.
                        </p>
                      </div>
                      <div className="p-2 mt-4">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                          <div className="mb-3">
                            <Label htmlFor="email" className="form-label">
                              Email
                            </Label>
                            <input
                              name="email"
                              type="text"
                              {...register("email")}
                              autoComplete="off"
                              className={`form-control ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              placeholder="Enter email"
                            />
                            <div className="invalid-feedback">
                              {errors.email?.message}
                            </div>
                          </div>

                          <div className="mb-3">
                            <div className="float-end">
                              <Link
                                className="text-muted"
                                to={`/forgot-password`}
                              >
                                Forgot password?
                              </Link>
                            </div>
                            <Label className="form-label">Password</Label>
                            <div className="position-relative auth-pass-inputgroup mb-3">
                              <input
                                name="password"
                                type="password"
                                {...register("password")}
                                className={`form-control ${
                                  errors.password ? "is-invalid" : ""
                                }`}
                                placeholder="Enter password"
                              />
                              <div className="invalid-feedback">
                                {errors.password?.message}
                              </div>
                            </div>
                          </div>

                          <div className="mt-4">
                            <Button
                              color="secondary"
                              className="btn btn-primary w-100"
                              disabled={disableForm}
                            >
                              Sign In
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </CardBody>
                  </Card>

                  <div className="mt-4 text-center">
                    <p className="mb-0">
                      {`Don't have an account ? `}
                      <Link
                        className="fw-semibold text-primary text-decoration-underline"
                        to={`/register`}
                      >
                        Signup
                      </Link>
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </ParticlesAuth>
      </div>
    </Fragment>
  );
};
