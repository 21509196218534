import { KEY_AUTH_TOKEN } from "_constants";
import { Fragment, useState } from "react";
import tenantConfig from "tenantConfig";

const API_URL = `${tenantConfig[process.env.REACT_APP_ENV].apiBaseUrl}`;

export const DownloadInvoiceButton = (props) => {
  const [loading, setLoading] = useState(false);
  const handleDownloadBtn = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem(KEY_AUTH_TOKEN);
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: token ? `Bearer ${token}` : "",
        },
        body: JSON.stringify({
          invoiceId: props.invoice._id,
        }),
      };
      const response = await fetch(
        `${API_URL}/api/v1/invoices/download-invoice`,
        options
      );
      setLoading(false);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();

      // Create a Blob URL for the file
      const url = window.URL.createObjectURL(blob);

      // Create a download link and trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = "invoice.pdf";
      a.click();

      // Release the Blob URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading Excel:", error);
    }
  };
  return (
    <Fragment>
      <button
        className="btn btn-sm btn-secondary m-2"
        onClick={handleDownloadBtn}
        disabled={loading}
      >
        Download
      </button>
    </Fragment>
  );
};
