import { useUserInfo } from "_hooks/Shared";
import { Fragment, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import tenantConfig from "tenantConfig";

export const GetTrackingLinkButton = ({ offer }) => {
  const {
    userInfo: { referenceId: userReferenceId },
  } = useUserInfo();
  const [modalTrackingUrl, setModalTrackingUrl] = useState(false);
  const trackingUrl = `${
    tenantConfig[process.env.REACT_APP_ENV].clockingUrl
  }?oid=${offer.referenceId}&uid=${userReferenceId}&ref1=`;

  const toggleModalTrackingUrl = () => setModalTrackingUrl(!modalTrackingUrl);

  return (
    <Fragment>
      <button className="btn btn-secondary" onClick={toggleModalTrackingUrl}>
        Get Link
      </button>
      <Modal isOpen={modalTrackingUrl} toggle={toggleModalTrackingUrl}>
        <ModalHeader toggle={toggleModalTrackingUrl}>
          {offer.offerName}
        </ModalHeader>
        <ModalBody>
          <h5 className="fs-15">{trackingUrl}</h5>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              navigator.clipboard.writeText(trackingUrl);
            }}
          >
            Copy
          </Button>
          <Button color="secondary" onClick={toggleModalTrackingUrl}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
